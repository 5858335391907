export {}

type UniqueOptions<T> = {
    /**
     * If a key is provided, the array will be filtered by the key.
     */
    key?: keyof T;
}

declare global {
    interface Array<T> {
        groupBy(callback: (el: T, index: number) => string): T[][];

        /** Returns a new array with unique elements */
        unique(options?: UniqueOptions<T>): Array<T>;
    }
}

if (!Array.prototype.groupBy) {
    Array.prototype.groupBy = function <T>(this: T[], callback: (el: T, index: number) => string): T[][] {
        const grouped: Array<{ key: string, elements: T[] }> = [];
        for (let i = 0; i < this.length; i++) {
            const key = callback(this.at(i) as T, i);
            const found = grouped.find((el) => el.key === key);
            if (found) {
                found.elements.push(this.at(i) as T);
            } else {
                grouped.push({
                    key,
                    elements: [this.at(i) as T],
                });
            }
        }
        return grouped.map((el) => el.elements);
    }
}

if (!Array.prototype.unique) {
    Array.prototype.unique = function <T>(this: T[], options?: UniqueOptions<T>): T[] {
        if (options?.key) {
            const key = options.key;
            return this.filter((item, index, self) =>
                index === self.findIndex((t) => (t as any)[key]?.toString() === (item as any)[key]?.toString()),
            );
        }
        return Array.from(new Set(this));
    }
}
