<template>
  <v-dialog :value="value" @input="reset" max-width="300" persistent dark style="z-index: 999">
    <v-card color="grey darken-3" class="no-user-select">
      <v-card-title class="pa-1">
        <v-spacer/>
        <v-btn small icon @click.stop="reset">
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div class="text-center pb-4">
          <v-icon color="warning" size="64">mdi-shield-lock</v-icon>
          <div>
            Passwort erforderlich
          </div>
        </div>
        <v-form ref="form" lazy-validation @submit.prevent="confirm">
          <v-text-field :rules="[RuleFactory.required()]" ref="passField" v-model="pass" color="warning" dense
                        hide-details rounded outlined type="password" label="Passwort eingeben"
                        @keydown.enter="confirm" @keydown.esc="reset"/>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import {RuleFactory} from '@/helpers/ruleFactory.helper';
import {Form} from '@/interfaces/Form.interface';

export default Vue.extend({
  props: {
    value: {
      type: Boolean,
    },
  },
  data: () => ({
    pass: '',
    valid: false,
  }),
  computed: {
    RuleFactory: () => RuleFactory,
  },
  methods: {
    confirm() {
      const form = this.$refs.form as Form;
      if (form.validate()) {
        this.$emit('commit', this.pass);
        this.reset();
      }
    },
    reset() {
      const form = this.$refs.form as Form;
      form.reset();
      this.$emit('input', false);
    },
    setFocus() {
      const field = this.$refs.passField as Vue;
      if (field) {
        const element = field.$el as HTMLElement;
        const inputField = element.querySelector('input');
        if (inputField) {
          inputField.focus();
        }
      }
    },
  },
  watch: {
    value(v) {
      const form = this.$refs.form as Form;
      if (v) {
        setTimeout(() => {
          this.setFocus();
          form?.resetValidation();
        }, 150);
      }
    },
  },
  mounted() {
    this.setFocus();
  },
});
</script>
