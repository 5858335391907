import {
    institutionFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/institutionFieldsDictionary';
import {
    eventInfoFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/eventInfoFields.dictionary';
import {
    eventTopicFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/eventTopicFields.dictionary';
import {
    holidayFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/holidayFields.dictionary';
import {
    parentFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/parentFields.dictionary';
import {
    studentFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/studentFields.dictionary';
import {
    loginUserFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/loginUserFields.dictionary';
import {
    contractFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/contractFields.dictionary';
import {
    subjectFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/subjectFields.dictionary';
import {roomFieldsDictionary} from '@/classes/clientOnly/permissionTreeResources/dictionaries/roomFields.dictionary';
import {
    documentTypeFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/documentTypeFields.dictionary';
import {CrudActionTypes} from '@/classes/clientOnly/permissionTreeResources/enums/CrudActionTypes';
import {
    responsibleFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/responsibleFields.dictionary';
import {
    configFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/configFields.dictionary';
import {
    customIdSchemeFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/customIdSchemeFields.dictionary';
import {statsFieldsDictionary} from '@/classes/clientOnly/permissionTreeResources/dictionaries/statsFields.dictionary';
import {
    articleFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/articleFields.dictionary';
import {
    warehouseOrderFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/warehouseOrderFields.dictionary';
import {
    storedFileFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/storedFileFields.dictionary';
import {
    invoiceTemplateFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/invoiceTemplateFields.dictionary';
import {stackFieldsDictionary} from '@/classes/clientOnly/permissionTreeResources/dictionaries/stackFields.dictionary';
import {
    handoutEventFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/handoutEventFields.dictionary';
import {roleFieldsDictionary} from '@/classes/clientOnly/permissionTreeResources/dictionaries/roleFields.dictionary';
import {
    terminationFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/terminationFieldsDictionary';
import {
    storedDocumentFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/storedDocumentFields.dictionary';
import {
    bookingFormTemplateFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/bookingFormTemplateFields.dictionary';
import {
    bookingFormFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/bookingFormFields.dictionary';
import {
    onlineFormSubmissionFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/onlineFormSubmissionFields.dictionary';
import {
    invoiceDocumentFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/invoiceDocumentFields.dictionary';
import {
    recurringDocumentFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/recurringDocumentFields.dictionary';
import {
    courseFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/courseFields.dictionary';
import {
    optimizationFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/optimizationFieldsDictionary';
import {
    optimizationSuggestionFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/optimizationSuggestionFieldsDictionary';
import {jobAdFieldsDictionary} from '@/classes/clientOnly/permissionTreeResources/dictionaries/jobAdFields.dictionary';
import {
    acquisitionListFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/acquisitionListFields.dictionary';
import {
    toDoItemFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/toDoItemFields.Dictionary';
import {
    messengerAccountFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/messengerAccountFields.dictionary';
import {
    whatsAppMessageTemplateFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/whatsAppMessageTemplateFields.dictionary';
import FieldDictionary from '@/classes/clientOnly/permissionTreeResources/dictionaries/fieldDictionary';
import {
    handoutFieldsDictionary,
} from '@/classes/clientOnly/permissionTreeResources/dictionaries/handoutFields.dictionary';
import {mapRecordToItems} from '@/helpers/mapRecordToItems.helper';
import {EmployeeFields} from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/EmployeeFields';
import {PaycheckFields} from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/PaycheckFields';
import {
    EmployeeContractFields,
} from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/EmployeeContractFields';
import {
    EmployeeContractTemplateFields,
} from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/EmployeeContractTemplateFields';
import {
    OneTimeSpecialFeeFields,
} from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/OneTimeSpecialFeeFields';
import {TaxonomyEntryFields} from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/TaxonomyEntryFields';

export enum CrudEntityTypes {
    ACQUISITION_LIST = 'acquisitionLists',
    ANY_DOCUMENT = 'anyDocuments', // For create and delete of all stored documents
    API_CONFIGURATION = 'apiConfigurations',
    ARTICLE = 'articles',
    BOOKING_FORM = 'bookingForms',
    BOOKING_FORM_TEMPLATE = 'bookingFormTemplates',
    CONFIG = 'configs',
    CONTACT = 'contact',
    CONTRACT = 'contracts',
    CONVERSATION = 'conversations',
    COURSE = 'courses',
    CRON_JOB = 'cronJobs',
    CUSTOMER = 'customer',
    CUSTOMER_MAIL_TEMPLATE = 'customerMailTemplates',
    CUSTOM_ID_SCHEME = 'customIdSchemes',
    DHL_STANDING_DATA = 'dhlStandingData',
    DOCUMENT_TYPE = 'documentTypes',
    EMPLOYEE = 'employees',
    EMPLOYEE_CONTRACT = 'employeeContracts',
    EMPLOYEE_CONTRACT_TEMPLATE = 'employeeContractTemplates',
    EVENT_INFO = 'eventInfos',
    EVENT_TOPIC = 'eventTopics',
    HANDOUT = 'handouts',
    HANDOUT_EVENT = 'handoutEvents',
    HANDOUT_LIST = 'handoutLists',
    HANDOUT_LIST_ITEM = 'handoutListItems',
    HANDOUT_TEMPLATE = 'handoutTemplates', // For create and delete of handout templates
    HOLIDAY = 'holidays',
    INSTITUTION = 'institutions',
    INVOICE_DOCUMENT = 'invoiceDocuments',
    INVOICE_TEMPLATE = 'invoiceTemplates',
    JOB_AD = 'jobAds',
    JOB_TYPE = 'jobTypes',
    LOGIN_USER = 'loginUsers',
    LOG_ENTRY = 'logEntries',
    MESSENGER_ACCOUNT = 'messengerAccounts',
    MESSENGER_MAIL = 'messengerMail',
    MESSENGER_WHATS_APP = 'messengerWhatsApp',
    NOTIFICATION = 'notifications',
    ONE_TIME_SPECIAL_FEE = 'oneTimeSpecialFees',
    ONLINE_FORM = 'onlineForms',
    ONLINE_FORM_SUBMISSION = 'onlineFormSubmissions',
    OPTIMIZATIONS = 'optimizations',
    OPTIMIZATION_SUGGESTIONS = 'optimizationSuggestions',
    OWN_DOCUMENT = 'ownDocuments', // For create and delete of own stored documents
    PARENT = 'parents',
    PAYCHECK = 'paychecks',
    PERMISSION_HOOK = 'permissionHooks',
    POST_STAMPS = 'postStamps',
    PRINTED_FORM = 'printedForms',
    RECURRING_DOCUMENT = 'recurringDocuments',
    RESPONSIBLE = 'responsibles',
    ROLE = 'roles',
    ROOM = 'rooms',
    SCHOOL_CLASS = 'schoolClasses',
    SHIPMENT_LABEL = 'shipmentLabels',
    SHORT_ID = 'shortIds',
    STACK = 'stacks',
    STATS = 'stats',
    STORED_DOCUMENT = 'storedDocuments', // For read and update access of stored documents
    STORED_FILE = 'storedFiles',
    STUDENT = 'students',
    SUBJECT = 'subjects',
    SUBSTITUTION_INQUIRY = 'substitutionInquiries',
    TAXONOMY_ENTRY = 'taxonomyEntries',
    TAG = 'tags',
    TERMINATION = 'terminations',
    TO_DO_ITEM = 'toDoItems',
    TO_DO_TEMPLATE = 'toDoTemplates',
    TRACKING = 'trackings',
    WAREHOUSE_ORDER = 'warehouseOrders',
    WEBHOOK = 'webhooks',
    WHATS_APP_MESSAGE_TEMPLATE = 'whatsAppMessageTemplates',
}

type FieldSpace = {
    Record: Record<string, { text: string; readOnly?: true; updateOnly?: true }>;
    Items: Array<{ value: string; text: string; readOnly?: true; updateOnly?: true }>;
}

export namespace CrudEntityTypes {
    type Data = {
        text: string;
        fieldSpace?: FieldSpace;
        actions?: CrudActionTypes[];
        fields?: FieldDictionary<string>;
        deprecated?: boolean;
    }

    export const Record: Record<CrudEntityTypes, Data> = {
        [CrudEntityTypes.ACQUISITION_LIST]: {
            text: 'Akquiselisten',
            fields: acquisitionListFieldsDictionary,
        },
        [CrudEntityTypes.TAXONOMY_ENTRY]: {
            text: 'Taxonomie-Einträge',
            actions: [CrudActionTypes.CREATE, CrudActionTypes.UPDATE, CrudActionTypes.DELETE],
            fieldSpace: TaxonomyEntryFields,
        },
        [CrudEntityTypes.ANY_DOCUMENT]: {
            text: 'Dokumente für jede Entität',
            actions: [CrudActionTypes.CREATE, CrudActionTypes.DELETE],
        },
        [CrudEntityTypes.API_CONFIGURATION]: {
            text: 'API-Konfigurationen',
        },
        [CrudEntityTypes.ARTICLE]: {
            text: 'Produkte',
            fields: articleFieldsDictionary,
            actions: [CrudActionTypes.READ, CrudActionTypes.CREATE, CrudActionTypes.UPDATE],
        },
        [CrudEntityTypes.BOOKING_FORM]: {
            text: 'Anmeldung - Anmeldeformulare (neu)',
            fields: bookingFormFieldsDictionary,
        },
        [CrudEntityTypes.BOOKING_FORM_TEMPLATE]: {
            text: 'Anmeldung - Vorlagen für Anmeldeformulare (neu)',
            fields: bookingFormTemplateFieldsDictionary,
        },
        [CrudEntityTypes.CONFIG]: {
            text: 'Konfigurationsdatei',
            fields: configFieldsDictionary,
            actions: [CrudActionTypes.READ, CrudActionTypes.UPDATE],
        },
        [CrudEntityTypes.CONTACT]: {
            text: 'FastBill - Kontakte',
            deprecated: true,
        },
        [CrudEntityTypes.CONTRACT]: {
            text: 'Verträge (alt)',
            fields: contractFieldsDictionary,
        },
        [CrudEntityTypes.CONVERSATION]: {
            text: 'Systemkonversationen',
            fields: roleFieldsDictionary,
        },
        [CrudEntityTypes.COURSE]: {
            text: 'Kurse',
            fields: courseFieldsDictionary,
        },
        [CrudEntityTypes.CRON_JOB]: {
            text: 'Automatisierungen',
        },
        [CrudEntityTypes.CUSTOM_ID_SCHEME]: {
            text: 'Referenznummern',
            fields: customIdSchemeFieldsDictionary,
        },
        [CrudEntityTypes.CUSTOMER]: {
            text: 'FastBill - Kunden',
            deprecated: true,
        },
        [CrudEntityTypes.CUSTOMER_MAIL_TEMPLATE]: {
            text: 'E-Mail-Vorlagen',
        },
        [CrudEntityTypes.DHL_STANDING_DATA]: {
            text: 'DHL Format- und Produktdaten',
        },
        [CrudEntityTypes.DOCUMENT_TYPE]: {
            text: 'Dokumenttypen',
            fields: documentTypeFieldsDictionary,
        },
        [CrudEntityTypes.EMPLOYEE]: {
            text: 'Mitarbeitende und Lehrkräfte',
            fieldSpace: EmployeeFields,
        },
        [CrudEntityTypes.EMPLOYEE_CONTRACT]: {
            text: 'Mitarbeiterverträge',
            fieldSpace: EmployeeContractFields,
        },
        [CrudEntityTypes.EMPLOYEE_CONTRACT_TEMPLATE]: {
            text: 'Vorlagen für Mitarbeiterverträge',
            fieldSpace: EmployeeContractTemplateFields,
        },
        [CrudEntityTypes.EVENT_INFO]: {
            text: 'Veranstaltungen',
            fields: eventInfoFieldsDictionary,
        },
        [CrudEntityTypes.EVENT_TOPIC]: {
            text: 'Veranstaltungsarten',
            fields: eventTopicFieldsDictionary,
        },
        [CrudEntityTypes.HANDOUT]: {
            text: 'Anmeldungen',
            fields: handoutFieldsDictionary,
            deprecated: true,
        },
        [CrudEntityTypes.HANDOUT_EVENT]: {
            text: 'Austeilaktionen',
            fields: handoutEventFieldsDictionary,
        },
        [CrudEntityTypes.HANDOUT_LIST]: {
            text: 'Austeillisten',
            deprecated: true,
        },
        [CrudEntityTypes.HANDOUT_LIST_ITEM]: {
            text: 'Austeillistenelemente',
            deprecated: true,
        },
        [CrudEntityTypes.HANDOUT_TEMPLATE]: {
            text: 'Vorlagen für Anmeldungen',
            actions: [CrudActionTypes.CREATE, CrudActionTypes.DELETE],
        },
        [CrudEntityTypes.HOLIDAY]: {
            text: 'Ferien und Feiertage',
            fields: holidayFieldsDictionary,
        },
        [CrudEntityTypes.INSTITUTION]: {
            text: 'Standorte',
            fields: institutionFieldsDictionary,
        },
        [CrudEntityTypes.INVOICE_DOCUMENT]: {
            text: 'Rechnungen (einmalig)',
            fields: invoiceDocumentFieldsDictionary,
        },
        [CrudEntityTypes.INVOICE_TEMPLATE]: {
            text: 'Rechnungsvorlagen',
            fields: invoiceTemplateFieldsDictionary,
        },
        [CrudEntityTypes.JOB_AD]: {
            text: 'Stellenanzeigen',
            fields: jobAdFieldsDictionary,
        },
        [CrudEntityTypes.JOB_TYPE]: {
            text: 'Jobposition',
            actions: [CrudActionTypes.READ, CrudActionTypes.CREATE, CrudActionTypes.DELETE],
        },
        [CrudEntityTypes.LOG_ENTRY]: {
            text: 'Protokolleinträge',
        },
        [CrudEntityTypes.LOGIN_USER]: {
            text: 'Benutzerzugänge',
            fields: loginUserFieldsDictionary,
        },
        [CrudEntityTypes.MESSENGER_ACCOUNT]: {
            text: 'Kommunikationsaccounts',
            fields: messengerAccountFieldsDictionary,
        },
        [CrudEntityTypes.MESSENGER_MAIL]: {
            text: 'E-Mails',
            actions: [CrudActionTypes.READ],
        },
        [CrudEntityTypes.MESSENGER_WHATS_APP]: {
            text: 'Whats-App-Nachrichten',
            actions: [CrudActionTypes.READ],
        },
        [CrudEntityTypes.NOTIFICATION]: {
            text: 'Benachrichtigungen',
            actions: [CrudActionTypes.READ],
        },
        [CrudEntityTypes.ONE_TIME_SPECIAL_FEE]: {
            text: 'Einmalige Sonderhonorare',
            fieldSpace: OneTimeSpecialFeeFields,
        },
        [CrudEntityTypes.ONLINE_FORM]: {
            text: 'Anmeldung - Onlinevorlagen',
            fields: onlineFormSubmissionFieldsDictionary,
        },
        [CrudEntityTypes.ONLINE_FORM_SUBMISSION]: {
            text: 'Anmeldung - Online-Anmeldungen',
            fields: onlineFormSubmissionFieldsDictionary,
        },
        [CrudEntityTypes.OPTIMIZATION_SUGGESTIONS]: {
            text: 'Optimierungsvorschläge',
            fields: optimizationSuggestionFieldsDictionary,
        },
        [CrudEntityTypes.OPTIMIZATIONS]: {
            text: 'Optimierungen',
            fields: optimizationFieldsDictionary,
        },
        [CrudEntityTypes.OWN_DOCUMENT]: {
            text: 'Eigene Dokumente',
            actions: [CrudActionTypes.CREATE, CrudActionTypes.DELETE],
        },
        [CrudEntityTypes.PARENT]: {
            text: 'Eltern',
            fields: parentFieldsDictionary,
        },
        [CrudEntityTypes.PAYCHECK]: {
            text: 'Lohnabrechnungen',
            fieldSpace: PaycheckFields,
        },
        [CrudEntityTypes.PERMISSION_HOOK]: {
            text: 'Berechtigungsroutinen',
        },
        [CrudEntityTypes.POST_STAMPS]: {
            text: 'DHL Internetmarken',
        },
        [CrudEntityTypes.PRINTED_FORM]: {
            text: 'Anmeldung - Druckvorlagen',
            fields: onlineFormSubmissionFieldsDictionary,
        },
        [CrudEntityTypes.RECURRING_DOCUMENT]: {
            text: 'Rechnungen (wiederkehrend)',
            fields: recurringDocumentFieldsDictionary,
        },
        [CrudEntityTypes.RESPONSIBLE]: {
            text: 'Verantwortliche',
            fields: responsibleFieldsDictionary,
        },
        [CrudEntityTypes.ROLE]: {
            text: 'Benutzergruppen',
            fields: roleFieldsDictionary,
        },
        [CrudEntityTypes.ROOM]: {
            text: 'Unterrichtsräume',
            fields: roomFieldsDictionary,
        },
        [CrudEntityTypes.SCHOOL_CLASS]: {
            text: 'Schulklassen',
        },
        [CrudEntityTypes.SHIPMENT_LABEL]: {
            text: 'Versandetikette',
            fields: roleFieldsDictionary,
        },
        [CrudEntityTypes.SHORT_ID]: {
            text: 'Kurz-IDs',
        },
        [CrudEntityTypes.STACK]: {
            text: 'Stapel',
            fields: stackFieldsDictionary,
        },
        [CrudEntityTypes.STATS]: {
            text: 'Statistiken',
            actions: [CrudActionTypes.READ],
            fields: statsFieldsDictionary,
        },
        [CrudEntityTypes.STORED_DOCUMENT]: {
            text: 'Hinterlegte Dokumente',
            actions: [CrudActionTypes.READ, CrudActionTypes.UPDATE, CrudActionTypes.CREATE],
            fields: storedDocumentFieldsDictionary,
        },
        [CrudEntityTypes.STORED_FILE]: {
            text: 'Hochgeladene Dateien',
            actions: [CrudActionTypes.READ, CrudActionTypes.UPDATE, CrudActionTypes.CREATE],
            fields: storedFileFieldsDictionary,
        },
        [CrudEntityTypes.STUDENT]: {
            text: 'Schüler:innen',
            fields: studentFieldsDictionary,
        },
        [CrudEntityTypes.SUBJECT]: {
            text: 'Fächer',
            fields: subjectFieldsDictionary,
        },
        [CrudEntityTypes.SUBSTITUTION_INQUIRY]: {
            text: 'Vertretungsanfragen',
        },
        [CrudEntityTypes.TAG]: {
            text: 'Tags',
        },
        [CrudEntityTypes.TERMINATION]: {
            text: 'Kündigungen',
            fields: terminationFieldsDictionary,
        },
        [CrudEntityTypes.TO_DO_ITEM]: {
            text: 'To-Dos',
            fields: toDoItemFieldsDictionary,
        },
        [CrudEntityTypes.TO_DO_TEMPLATE]: {
            text: 'To-Do-Vorlagen',
        },
        [CrudEntityTypes.TRACKING]: {
            text: 'Trackinginformationen',
        },
        [CrudEntityTypes.WAREHOUSE_ORDER]: {
            text: 'Bestellungen',
            fields: warehouseOrderFieldsDictionary,
        },
        [CrudEntityTypes.WEBHOOK]: {
            text: 'Webhooks',
        },
        [CrudEntityTypes.WHATS_APP_MESSAGE_TEMPLATE]: {
            text: 'WhatsApp-Nachrichtenvorlagen',
            fields: whatsAppMessageTemplateFieldsDictionary,
        },
    }

    export const Items = mapRecordToItems(Record);
    Items.sort((a, b) => {
        if (a.deprecated) {
            return 1;
        } else if (b.deprecated) {
            return -1;
        }
        return a.text.localeCompare(b.text);
    });
    for (const item of Items) {
        if (item.fields) {
            item.fields.sort((a: { text: string }, b: { text: string }) => a.text.localeCompare(b.text));
        }
        if (item.fieldSpace) {
            const fields = item.fieldSpace.Items;
            if (fields) {
                fields.sort((a: { text: string }, b: { text: string }) => a.text.localeCompare(b.text));
            }
        }
    }
}
