import {VanillaException} from '@/exceptions/VanillaException';
import {AxiosError, isAxiosError} from 'axios';
import {ClassConstructor, plainToInstance} from 'class-transformer';

import(/* webpackChunkName: "api-handler-exceptions" */ './api-handler');
import(/* webpackChunkName: "authenticator-exceptions" */'./authenticator');
import(/* webpackChunkName: "common-exceptions" */'./common');
import(/* webpackChunkName: "payoff-manager-exceptions" */'./payoff-manager');
import(/* webpackChunkName: "taxonomy-manager-exceptions" */ './taxonomy-manager');
import(/* webpackChunkName: "user-login-handler-exceptions" */'./user-login-handler');

export abstract class ExceptionsCollector {
    static exceptions: Map<string, Array<ClassConstructor<VanillaException>>> = new Map();

    static registerException(namespace: string, exception: ClassConstructor<VanillaException>) {
        if (this.exceptions.has(namespace)) {
            this.exceptions.get(namespace)?.push(exception);
        } else {
            this.exceptions.set(namespace, [exception]);
        }
    }

    /**
     * Tries to cast an exception to a known exception type.
     * Returns the cast exception or false if the exception is not known.
     * @param exception The exception to cast - can be a string, an object or an AxiosError
     * @returns The cast exception or false
     */
    static tryCast(exception: any): VanillaException | false {
        if (!exception) {
            return false;
        }
        if (exception instanceof VanillaException) {
            return exception;
        }

        if (isAxiosError(exception)) {
            exception = (exception as AxiosError).response?.data;
            if (!exception) {
                return false;
            }
        }
        if (typeof exception === 'string') {
            try {
                exception = JSON.parse(exception);
            } catch (e) {
                return false;
            }
        }
        if (!exception.hasOwnProperty('_t') || !exception.hasOwnProperty('_n')) {
            return false;
        }
        for (const e of this.exceptions.get(exception._n) || []) {
            if (e.name === exception._t) {
                return plainToInstance(e, exception);
            }
        }
        return false;
    }
}
