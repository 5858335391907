import store from '@/store';
import Axios, {AxiosInstance, AxiosResponse} from 'axios';
import router from '@/router';
import CrudAccess from '@/classes/clientOnly/permissionTreeResources/interfaces/CrudAccess';
import {LoginResponseDto} from '@/classes/dto/authenticator/response/LoginResponseDto';
import {CheckLoginResponseDto} from '@/classes/dto/authenticator/response/CheckLogin.response.dto';
import {Types} from "mongoose";
import {NotifierSocketApi} from '@/classes/api/notifier.socket.api';
import {NavigationDrawerAccess} from '@/enums/_common/NavigationDrawerAccess.enum';
import {ExceptionsCollector} from '@/exceptions/ExceptionsCollector';
import {VanillaException} from '@/exceptions/VanillaException';
import {LoginTemporarilyRequestDto} from '@/classes/dto/authenticator/request/LoginTemporarily.request.dto';

/**
 * This class is used for communicating with Institutioner microservice
 * @class InstitutionerApi
 * @author mklaczinski
 */
export class LoginApi {
    private static service = 'authenticator';
    private static accessToken = '';

    static async login(mail: string, password: string): Promise<LoginResponseDto> {
        try {
            const credentials = (await Axios.post(`${process.env.VUE_APP_API_PATH}/${this.service}/login`, {
                username: mail,
                password,
            }, {
                withCredentials: true,
            })).data;
            this.handleCredentials(credentials);
            await NotifierSocketApi.connect();
            return credentials;
        } catch (e) {
            throw this.transformException(e);
        }
    }

    static async loginAs(employeeId: Types.ObjectId) {
        try {
            const credentials = (await Axios.post(`${process.env.VUE_APP_API_PATH}/${this.service}/loginAs`, {
                employeeId,
            }, {
                withCredentials: true,
            })).data;
            this.handleCredentials(credentials);
            window.location.reload();
        } catch (e) {
            throw this.transformException(e);
        }
    }

    static async loginTemporarily(data: LoginTemporarilyRequestDto) {
        const axiosInstance = store.state.axios;
        if (!axiosInstance) {
            throw new Error('Axios instance not defined');
        }
        try {
            const credentials = (await axiosInstance.post(`${process.env.VUE_APP_API_PATH}/${this.service}/loginTemporarily`, data, {
                withCredentials: true,
            })).data;
            this.handleCredentials(credentials);
            localStorage.setItem('temp-login-from', router.currentRoute.path);
            router.push('/').catch().then(() => {
                window.location.reload();
            });
        } catch (e) {
            throw this.transformException(e);
        }
    }

    static async loginAsOrigin() {
        try {
            const credentials = (await Axios.post(`${process.env.VUE_APP_API_PATH}/${this.service}/loginAsOrigin`, {
            }, {
                withCredentials: true,
            })).data;
            this.handleCredentials(credentials);
            const tempLoginFrom = localStorage.getItem('temp-login-from');
            if (tempLoginFrom) {
                router.push(tempLoginFrom).catch().then(() => {
                    localStorage.removeItem('temp-login-from');
                    window.location.reload();
                });
            } else {
                window.location.reload();
            }
        } catch (e) {
            throw this.transformException(e);
        }
    }

    static async checkLogin(): Promise<CheckLoginResponseDto> {
        try {
            let instance: AxiosInstance;
            if (this.accessToken) {
                instance = Axios.create({
                    headers: {
                        Authorization: `Bearer ${this.accessToken}`,
                    },
                    baseURL: process.env.VUE_APP_API_PATH,
                    withCredentials: true,
                });
            } else {
                instance = Axios.create({
                    baseURL: process.env.VUE_APP_API_PATH,
                    withCredentials: true,
                });
            }
            return (await instance.post(`${this.service}/checkLogin`)).data as CheckLoginResponseDto;
        } catch (e) {
            throw this.transformException(e);
        }
    }

    static async logout(): Promise<void> {
        try {
            await Axios.create({withCredentials: true}).post(`${process.env.VUE_APP_API_PATH}/${this.service}/logout`);
            store.commit('logout');
            await NotifierSocketApi.disconnect();
            router.push('/login').catch();
            window.location.reload();
        } catch (e) {
            throw this.transformException(e);
        }
    }

    /**
     * Retrieves the user's crud access from permissioner and uses it to create
     * a new instance of CrudAccessManager in store.
     * This method was implemented here because using any child classes of Api.class.ts
     * before authorization results in an error.
     * Handle with care.
     */
    public static async setCrud(): Promise<void> {
        const axiosInstance = store.state.axios;
        if (!axiosInstance) {
            throw new Error('Axios instance not defined');
        }
        try {
            const resp: AxiosResponse<CrudAccess> = await axiosInstance.get(`${process.env.VUE_APP_API_PATH}/permissioner/crud`);
            store.commit('setCrudAccess', resp.data);
            const guiResp: AxiosResponse<NavigationDrawerAccess> = await axiosInstance.get(`${process.env.VUE_APP_API_PATH}/permissioner/gui`);
            store.commit('setGuiAccess', guiResp.data);
        } catch (e) {
            throw this.transformException(e);
        }
    }

    /**
     * Handles the credentials received from the login endpoint and places them in the store
     * @param credentials - the credentials received from the login endpoint
     * @private
     */
    private static handleCredentials(credentials: any) {
        this.accessToken = credentials.token.access_token;
        store.commit('user', credentials.meta);
        store.commit('setLoginUserId', credentials.loginUserId);
        store.commit('login', this.accessToken);
        store.commit('setUserType', credentials.type);
    }

    private static transformException<T>(e: T): VanillaException | T {
        const vanillaException = ExceptionsCollector.tryCast(e);
        if (vanillaException) {
            return vanillaException;
        }
        return e;
    }

}
