import {mapRecordToItems} from '@/helpers/mapRecordToItems.helper';
import store from '@/store';
import {LoginTypes} from '@/enums/LoginTypes.enum';

export enum NavigationDrawerGroups {
    PROFILES = 'PROFILES',
    EVENTS = 'EVENTS',
    QUALITY_MANAGEMENT = 'QUALITY_MANAGEMENT',
    ACQUISITION_LISTS = 'ACQUISITION_LISTS',
    PLANNING = 'PLANNING',
    TOOLS = 'TOOLS',
    JOB_ADS = 'JOB_ADS',
    TEMPLATES = 'TEMPLATES',
    OTHER = 'OTHER',
}

export namespace NavigationDrawerGroups {
    type Data = {
        text: string;
        icon: string;
    }

    export const Record: Record<NavigationDrawerGroups, Data> = {
        [NavigationDrawerGroups.PROFILES]: {
            text: 'Profile',
            icon: 'mdi-account-group',
        },
        [NavigationDrawerGroups.EVENTS]: {
            text: 'Veranstaltungen',
            icon: 'mdi-calendar',
        },
        [NavigationDrawerGroups.QUALITY_MANAGEMENT]: {
            text: 'Qualitätsmanagement',
            icon: 'mdi-poll',
        },
        [NavigationDrawerGroups.ACQUISITION_LISTS]: {
            text: 'Akquise-Listen',
            icon: 'mdi-room-service',
        },
        [NavigationDrawerGroups.PLANNING]: {
            text: 'Planung',
            icon: 'mdi-clipboard-text',
        },
        [NavigationDrawerGroups.TOOLS]: {
            text: 'Werkzeuge',
            icon: 'mdi-tools',
        },
        [NavigationDrawerGroups.JOB_ADS]: {
            text: 'Stellenanzeigen',
            icon: 'mdi-account-tie',
        },
        [NavigationDrawerGroups.TEMPLATES]: {
            text: 'Vorlagen',
            icon: 'mdi-file-document',
        },
        [NavigationDrawerGroups.OTHER]: {
            text: 'Sonstige',
            icon: 'mdi-dots-horizontal',
        },
    }

    export const Items = mapRecordToItems(Record);
}

export enum NavigationDrawerAccess {
    DASHBOARD = 'DASHBOARD',
    MY_SCHEDULE = 'MY_SCHEDULE',
    MY_PROFILE = 'MY_PROFILE',
    PAYOFF = 'PAYOFF',
    PAYOFF_AUDIT = 'PAYOFF_AUDIT',
    MY_APPOINTMENTS = 'MY_APPOINTMENTS',
    COURSES = 'COURSES',
    SUBSTITUTION_PLAN = 'SUBSTITUTION_PLAN',
    SUBSTITUTION_INQUIRIES = 'SUBSTITUTION_INQUIRIES',
    SUBSTITUTION_INQUIRIES_TABLE = 'SUBSTITUTION_INQUIRIES_TABLE',
    INSTITUTIONS = 'INSTITUTIONS',
    QUALITY_MANAGEMENT_TEACHERS = 'QUALITY_MANAGEMENT_TEACHERS',
    QUALITY_MANAGEMENT_INSTITUTIONS = 'QUALITY_MANAGEMENT_INSTITUTIONS',
    ACQUISITION_LIST_OVERVIEW = 'ACQUISITION_LIST_OVERVIEW',
    ACQUISITION_LISTS_TABLE = 'ACQUISITION_LISTS_TABLE',
    STUDENTS = 'STUDENTS',
    PARENTS = 'PARENTS',
    EMPLOYEES = 'EMPLOYEES',
    TEACHERS = 'TEACHERS',
    STATS = 'STATS',
    PLANNING_HANDOUT_EVENTS = 'PLANNING_HANDOUT_EVENTS',
    PLANNING_HANDOUT_OVERVIEW = 'PLANNING_HANDOUT_OVERVIEW',
    PLANNING_HANDOUT_FEEDBACK = 'PLANNING_HANDOUT_FEEDBACK',
    TOOLS_MISSING_DOCUMENTS = 'TOOLS_MISSING_DOCUMENTS',
    TOOLS_TAGS = 'TOOLS_TAGS',
    TOOLS_SEPA = 'TOOLS_SEPA',
    TOOLS_BOOK_INVOICE_GENERATOR = 'TOOLS_BOOK_INVOICE_GENERATOR',
    TOOLS_MIGRATION = 'TOOLS_MIGRATION',
    JOB_ADS_OVERVIEW = 'JOB_ADS_OVERVIEW',
    JOB_ADS_APPLICANTS = 'JOB_ADS_APPLICANTS',
    INVOICE_TEMPLATES = 'INVOICE_TEMPLATES',
    EMPLOYEE_CONTRACT_TEMPLATES = 'EMPLOYEE_CONTRACT_TEMPLATES',
    EMAIL_TEMPLATES = 'EMAIL_TEMPLATES',
    WHATSAPP_TEMPLATES = 'WHATSAPP_TEMPLATES',
    BOOKING_FORM_TEMPLATES = 'BOOKING_FORM_TEMPLATES',
    SUBMISSIONS = 'SUBMISSIONS',
    SUBJECTS = 'SUBJECTS',
    PRODUCTS = 'PRODUCTS',
    TERMINATIONS = 'TERMINATIONS',
    VACATIONS = 'VACATIONS',
    TAXONOMIES = 'TAXONOMIES',
    TRAININGS = 'TRAININGS',
    TODOS = 'TODOS',
    // OPTIMIZATIONS = 'OPTIMIZATIONS',
    SETTINGS = 'SETTINGS',
}

export namespace NavigationDrawerAccess {
    type Data = {
        text: string;
        icon: string;
        to: string | (() => string | null);
        group?: NavigationDrawerGroups;
        badge?: {
            color: string;
            value: string | number | (() => string | number | null | undefined);
            description?: string | number | (() => string | number | null | undefined);
        }
    }

    export const Record: Record<NavigationDrawerAccess, Data> = {
        [NavigationDrawerAccess.DASHBOARD]: {
            text: 'Dashboard',
            icon: 'mdi-view-dashboard',
            to: '/',
        },
        [NavigationDrawerAccess.TODOS]: {
            text: 'To-Dos',
            icon: 'mdi-format-list-checks',
            to: '/todos',
        },
        [NavigationDrawerAccess.MY_SCHEDULE]: {
            text: 'Mein Stundenplan',
            icon: 'mdi-clock-outline',
            to: '/schedule',
        },
        [NavigationDrawerAccess.MY_PROFILE]: {
            text: 'Mein Profil',
            icon: 'mdi-account',
            to() {
                if (!store.state.userType || !store.state.user) {
                    return null;
                }
                switch (store.state.userType) {
                    case LoginTypes.EMPLOYEE:
                        return `/employees/${store.state.user._id}`;
                    case LoginTypes.STUDENT:
                        return `/students/${store.state.user._id}`;
                    case LoginTypes.PARENT:
                        return `/parents/${store.state.user._id}`;
                    default:
                        return null;
                }
            },
        },
        [NavigationDrawerAccess.PAYOFF]: {
            text: 'Abrechnung',
            icon: 'mdi-account-cash',
            to: '/payoff',
        },
        [NavigationDrawerAccess.PAYOFF_AUDIT]: {
            text: 'Rechnungsprüfung',
            icon: 'mdi-cash-check',
            to: '/payoff-audit',
            badge: {
                color: 'error',
                value: () => store.state.pendingPaychecksCount,
                description() {
                    if (store.state.pendingPaychecksCount) {
                        const pendingPaychecksCount = store.state.pendingPaychecksCount;
                        return `Derzeit gibt es ${pendingPaychecksCount} zu prüfende ${pendingPaychecksCount === 1 ? 'Rechnung' : 'Rechnungen'}.`;
                    }
                    return undefined;
                },
            },
        },
        // @todo: handle /my-appointments
        [NavigationDrawerAccess.MY_APPOINTMENTS]: {
            text: 'Meine Termine',
            icon: 'mdi-calendar-clock',
            to: '/my-appointments',
        },
        [NavigationDrawerAccess.COURSES]: {
            text: 'Alle Kurse',
            icon: 'mdi-table-account',
            to: '/courses',
            group: NavigationDrawerGroups.EVENTS,
        },
        [NavigationDrawerAccess.SUBSTITUTION_PLAN]: {
            text: 'Vertretungsplan',
            icon: 'mdi-account-switch',
            to: '/substitution-plan',
            group: NavigationDrawerGroups.EVENTS,
        },
        [NavigationDrawerAccess.SUBSTITUTION_INQUIRIES]: {
            text: 'Mögliche Vertretungen',
            icon: 'mdi-account-switch-outline',
            to: '/substitution-inquiries',
        },
        [NavigationDrawerAccess.SUBSTITUTION_INQUIRIES_TABLE]: {
            text: 'Vertretungsanfragen',
            icon: 'mdi-account-search',
            to: '/substitution-inquiries-table',
            group: NavigationDrawerGroups.EVENTS,
        },
        [NavigationDrawerAccess.INSTITUTIONS]: {
            text: 'Standorte',
            icon: 'mdi-domain',
            to: '/institutions',
            group: NavigationDrawerGroups.PROFILES,
        },
        [NavigationDrawerAccess.STUDENTS]: {
            text: 'Schüler:innen',
            icon: 'mdi-school',
            to: '/students',
            group: NavigationDrawerGroups.PROFILES,
        },
        [NavigationDrawerAccess.PARENTS]: {
            text: 'Eltern',
            icon: 'mdi-account-child',
            to: '/parents',
            group: NavigationDrawerGroups.PROFILES,
        },
        [NavigationDrawerAccess.EMPLOYEES]: {
            text: 'Mitarbeitende',
            icon: 'mdi-account-hard-hat',
            to: '/employees',
            group: NavigationDrawerGroups.PROFILES,
        },
        [NavigationDrawerAccess.TEACHERS]: {
            text: 'Lehrkräfte',
            icon: 'mdi-human-male-board',
            to: '/teachers',
            group: NavigationDrawerGroups.PROFILES,
        },
        [NavigationDrawerAccess.QUALITY_MANAGEMENT_TEACHERS]: {
            text: 'Lehrkräfte - QM',
            icon: 'mdi-human-male-board-poll',
            to: '/qualityManagement/teachers',
            group: NavigationDrawerGroups.QUALITY_MANAGEMENT,
        },
        [NavigationDrawerAccess.QUALITY_MANAGEMENT_INSTITUTIONS]: {
            text: 'Standorte - QM',
            icon: 'mdi-office-building',
            to: '/qualityManagement/institutions',
            group: NavigationDrawerGroups.QUALITY_MANAGEMENT,
        },
        [NavigationDrawerAccess.ACQUISITION_LIST_OVERVIEW]: {
            text: 'Übersicht',
            icon: 'mdi-list-box',
            to: '/acquisitionListsOverview',
            group: NavigationDrawerGroups.ACQUISITION_LISTS,
        },
        [NavigationDrawerAccess.ACQUISITION_LISTS_TABLE]: {
            text: 'Alle Listen',
            icon: 'mdi-list-status',
            to: '/acquisitionLists',
            group: NavigationDrawerGroups.ACQUISITION_LISTS,
        },
        [NavigationDrawerAccess.STATS]: {
            text: 'Statistiken',
            icon: 'mdi-chart-line',
            to: '/stats',
        },
        [NavigationDrawerAccess.PLANNING_HANDOUT_EVENTS]: {
            text: 'Austeilaktionen',
            icon: 'mdi-text-box-check-outline',
            to: '/planning/handout-events',
            group: NavigationDrawerGroups.PLANNING,
        },
        [NavigationDrawerAccess.PLANNING_HANDOUT_OVERVIEW]: {
            text: 'Terminübersicht',
            icon: 'mdi-calendar-alert',
            to: '/planning/handout-overview',
            group: NavigationDrawerGroups.PLANNING,
        },
        [NavigationDrawerAccess.PLANNING_HANDOUT_FEEDBACK]: {
            text: 'Feedback',
            icon: 'mdi-comment-alert',
            to: '/planning/handout-events-feedback',
            group: NavigationDrawerGroups.PLANNING,
        },
        [NavigationDrawerAccess.JOB_ADS_OVERVIEW]: {
            text: 'Stellenanzeigen',
            icon: 'mdi-newspaper-variant-outline',
            to: '/jobAds',
            group: NavigationDrawerGroups.JOB_ADS,
        },
        [NavigationDrawerAccess.JOB_ADS_APPLICANTS]: {
            text: 'Bewerber:innen',
            icon: 'mdi-account-tie-voice',
            to: '/applicants',
            group: NavigationDrawerGroups.JOB_ADS,
        },
        [NavigationDrawerAccess.INVOICE_TEMPLATES]: {
            text: 'Rechnungsvorlagen',
            icon: 'mdi-clipboard-account',
            to: '/templates/invoice',
            group: NavigationDrawerGroups.TEMPLATES,
        },
        [NavigationDrawerAccess.EMPLOYEE_CONTRACT_TEMPLATES]: {
            text: 'Vergütungsverträge',
            icon: 'mdi-file-document-edit',
            to: '/templates/contract-templates',
            group: NavigationDrawerGroups.TEMPLATES,
        },
        [NavigationDrawerAccess.EMAIL_TEMPLATES]: {
            text: 'E-Mail Vorlagen',
            icon: 'mdi-email-edit',
            to: '/templates/mail',
            group: NavigationDrawerGroups.TEMPLATES,
        },
        [NavigationDrawerAccess.WHATSAPP_TEMPLATES]: {
            text: 'WhatsApp Vorlagen',
            icon: 'mdi-whatsapp',
            to: '/templates/whatsapp',
            group: NavigationDrawerGroups.TEMPLATES,
        },
        [NavigationDrawerAccess.BOOKING_FORM_TEMPLATES]: {
            text: 'Anmeldevorlagen',
            icon: 'mdi-file-document-edit',
            to: '/booking',
            group: NavigationDrawerGroups.TEMPLATES,
        },
        [NavigationDrawerAccess.SUBMISSIONS]: {
            text: 'Anmeldungen',
            icon: 'mdi-cart-arrow-down',
            to: '/submissions',
            badge: {
                color: 'success',
                value: () => store.state.openSubmissions,
                description() {
                    if (store.state.openSubmissions) {
                        const openSubmissions = store.state.openSubmissions;
                        return `Derzeit gibt es ${openSubmissions} nicht bearbeitete ${openSubmissions === 1 ? 'Anmeldung' : 'Anmeldungen'}.`;
                    }
                    return undefined;
                },
            },
        },
        [NavigationDrawerAccess.PRODUCTS]: {
            text: 'Logistik',
            icon: 'mdi-warehouse',
            to: '/products',
            badge: {
                color: 'success',
                value: () => store.state.openOrders,
                description() {
                    if (store.state.openOrders) {
                        const openOrders = store.state.openOrders;
                        return `Derzeit gibt es ${openOrders} offene ${openOrders === 1 ? 'Bestellung' : 'Bestellungen'}.`;
                    }
                    return undefined;
                },
            },
        },
        [NavigationDrawerAccess.TERMINATIONS]: {
            text: 'Kündigungen',
            icon: 'mdi-cancel',
            to: '/terminations',
            badge: {
                color: 'error',
                value: () => store.state.openTerminations,
                description() {
                    if (store.state.openTerminations) {
                        const openTerminations = store.state.openTerminations;
                        return `Im aktuellen Monat gibt es ${openTerminations} ${openTerminations === 1 ? 'Kündigung' : 'Kündigungen'}.`;
                    }
                    return undefined;
                },
            },
        },
        [NavigationDrawerAccess.TRAININGS]: {
            text: 'Schulungen',
            icon: 'mdi-cast-education',
            to: '/trainings',
            group: NavigationDrawerGroups.EVENTS,
        },
        [NavigationDrawerAccess.TOOLS_MISSING_DOCUMENTS]: {
            text: 'Fehlende Dokumente',
            icon: 'mdi-file-cancel',
            to: '/tools/missingDocuments',
            group: NavigationDrawerGroups.TOOLS,
        },
        [NavigationDrawerAccess.TOOLS_TAGS]: {
            text: 'Tags',
            icon: 'mdi-tag',
            to: '/tools/tags',
            group: NavigationDrawerGroups.TOOLS,
        },
        [NavigationDrawerAccess.TOOLS_SEPA]: {
            text: 'SEPA',
            icon: 'mdi-bank-transfer-out',
            to: '/tools/sepaTools',
            group: NavigationDrawerGroups.TOOLS,
        },
        [NavigationDrawerAccess.TOOLS_BOOK_INVOICE_GENERATOR]: {
            text: 'Buchrechner',
            icon: 'mdi-book-cog',
            to: '/tools/bookInvoiceGenerator',
            group: NavigationDrawerGroups.TOOLS,
        },
        [NavigationDrawerAccess.TOOLS_MIGRATION]: {
            text: 'Migration',
            icon: 'mdi-database-refresh',
            to: '/tools/migration',
            group: NavigationDrawerGroups.TOOLS,
        },
        [NavigationDrawerAccess.SUBJECTS]: {
            text: 'Fächer',
            icon: 'mdi-book',
            to: '/subjects',
            group: NavigationDrawerGroups.OTHER,
        },
        [NavigationDrawerAccess.VACATIONS]: {
            text: 'Ferien und Pausen',
            icon: 'mdi-beach',
            to: '/vacation',
            group: NavigationDrawerGroups.OTHER,
        },
        [NavigationDrawerAccess.TAXONOMIES]: {
            text: 'Taxonomien',
            icon: 'mdi-format-list-bulleted',
            to: '/taxonomies',
            group: NavigationDrawerGroups.OTHER,
        },
        // [NavigationDrawerAccess.OPTIMIZATIONS]: {
        //     text: 'Optimierungen',
        //     icon: 'mdi-calculator-variant',
        //     to: '/optimizations',
        // },
        [NavigationDrawerAccess.SETTINGS]: {
            text: 'Einstellungen',
            icon: 'mdi-wrench',
            to: '/settings',
        },
    }

    export const Items = mapRecordToItems(Record);

    export type DrawerGroupItem = {
        type: 'group',
        group: NavigationDrawerGroups,
        text: string,
        icon: string,
        items: DrawerItem[],
    };

    export type DrawerDataItem = { type: 'item' } & Data;

    export type DrawerItem = DrawerGroupItem | DrawerDataItem;

    export const getDrawerItems = (access: NavigationDrawerAccess[]): DrawerItem[] => {
        const accessibleItems = Items.filter(item => access.includes(item.value));

        const result: DrawerItem[] = [];
        for (const item of accessibleItems) {
            if (item.group) {
                const group = result.find(i => i.type === 'group' && i.group === item.group);
                if (group?.type === 'group') {
                    group.items.push({type: 'item', ...item});
                } else {
                    result.push({
                        type: 'group',
                        group: item.group,
                        ...NavigationDrawerGroups.Record[item.group],
                        items: [{type: 'item', ...item}],
                    });
                }
            } else {
                result.push({type: 'item', ...item});
            }
        }

        return result;
    }
}
