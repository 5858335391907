<template>
  <v-alert v-if="notification.showInApp" :key="notification.id" class="elevation-3 mt-4 mx-2 notification" dark
           transition="slide-x-transition">
    <template v-slot:prepend>
      <div class="mr-4">
        <v-icon>mdi-check-circle</v-icon>
      </div>
    </template>
    <template v-slot:default>
      <div class="body-2">
        {{ notification.messagePayload.responsibleName }} hat eine Aufgabe erledigt
      </div>
      <v-card class="mt-2" light>
        <v-list-item>
          <v-list-item-avatar>
            <v-simple-checkbox :value="toDoValue" color="error" disabled/>
          </v-list-item-avatar>
          <v-list-item-content class="body-2">
            <overflow-text :text="notification.messagePayload.name"/>
            <v-list-item-subtitle v-if="notification.messagePayload.completionComment">
              <overflow-text :text="'Kommentar:'+ notification.messagePayload.completionComment" :max-length="200"/>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="success--text" v-if="getDataString">
              {{getDataString}}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card>
      <v-btn :href="link" class="mt-2" @click="hide" small target="_blank" text>
        <v-icon class="mr-2" small>mdi-page-next-outline</v-icon>
        Profil öffnen
      </v-btn>
      <to-do-notification-acquisition-lists-link-button :notification="notification" v-if="notification"/>
    </template>
    <template v-slot:close>
      <v-btn class="ml-2" icon small @click="hide">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-alert>
</template>
<script lang="ts">
import Vue from 'vue';
import {NotificationLike} from '@/types/NotificationLike.type';
import {NotifierApi} from '@/classes/api/notifier.api';
import OverflowText from '@/components/common/overflow-text.vue';
import {
  ToDoCompletedNotificationDocument,
} from '@/documentTypes/notifier/discriminators/ToDoCompletedNotification.document';
import ToDoNotificationAcquisitionListsLinkButton
  from "@/components/app/appBar/notifications/appNotifications/components/todoHelpers/toDoNotificationAcquisitionListsLinkButton.vue";
import {ToDoDataTypesEnum} from "@/enums/to-do-manager/ToDoDataTypes.enum";
import moment from "moment";
moment.locale('de');

export default Vue.extend({
  components: {ToDoNotificationAcquisitionListsLinkButton, OverflowText},
  props: {
    notification: {
      type: Object as () => NotificationLike<ToDoCompletedNotificationDocument>,
      required: true,
    },
  },
  computed: {
    link(): string {
      return this.$router.resolve(`/${this.notification.messagePayload.entityType}/${this.notification.messagePayload.entityId}`).href;
    },
    getDataString(): string | number | undefined {
      const data = this.notification.messagePayload.data as boolean | Date | string | string[] | undefined | number;
      if (typeof data === 'undefined') {
        return undefined;
      }
      if (typeof data === 'boolean') {
        return data ? 'Ja' : 'Nein';
      } else if (this.notification.messagePayload.dataType === ToDoDataTypesEnum.DATE) {
        return moment(data).format('DD.MM.YYYY');
      } else if (Array.isArray(data)) {
        return data.join(', ');
      } else {
        return data as unknown as string | number ;
      }
    },
  },
  data: () => ({
    toDoValue: true,
  }),
  methods: {
    async hide() {
      const id = this.notification._id;
      await NotifierApi.markRead({
        notificationIds: [id],
      });
      this.$store.commit('hideNotification', id);
    },
  },
});
</script>

<style scoped>
.notification {
  background-color: rgba(0, 0, 10, 0.5) !important;
  backdrop-filter: blur(15px);
}
</style>
