<template>
  <v-badge style="width: 100%" :value="!notification.readAt" color="success" dot offset-x="25" offset-y="20">
    <v-list-item :href="$router.resolve(`/todos/${notification.messagePayload.toDoId}`).href" :class="{ 'success lighten-5': !notification.readAt }">
      <v-list-item-avatar>
        <v-icon color="success">mdi-check-circle</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-subtitle>
          {{ notification.messagePayload.responsibleName }} hat eine Aufgabe erledigt
        </v-list-item-subtitle>
        <v-list-item-title>
          <overflow-text :text="notification.messagePayload.name"/>
        </v-list-item-title>
        <v-list-item-subtitle v-if="!notification.dismissedAt">
          {{ moment(notification.createdAt).fromNow() }}
        </v-list-item-subtitle>
        <v-list-item-subtitle v-else>
          <v-icon small>mdi-delete</v-icon>
          {{ moment(notification.dismissedAt).fromNow() }} gelöscht
        </v-list-item-subtitle>
        <v-list-item-subtitle v-if="notification.messagePayload.completionComment">
          <overflow-text :text="'Kommentar:'+ notification.messagePayload.completionComment" :max-length="200"/>
        </v-list-item-subtitle>
        <v-list-item-subtitle class="success--text" v-if="getDataString">
          {{getDataString}}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-tooltip bottom>
          <template v-slot:activator="{on}">
            <v-btn :href="link" icon target="_blank" v-on="on">
              <v-icon>mdi-page-next-outline</v-icon>
            </v-btn>
          </template>
          <span>Ansehen</span>
        </v-tooltip>

      </v-list-item-action>
      <v-list-item-action>
        <to-do-notification-acquisition-lists-link-button show-tooltip :notification="notification"
                                                          v-if="notification"/>
      </v-list-item-action>
      <v-list-item-action class="ml-0" v-if="!notification.dismissedAt">
        <v-btn icon @click="$emit('dismiss', notification._id)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-list-item-action>
      <v-list-item-action class="ml-0" v-else>
        <v-btn icon @click="$emit('revoke', notification._id)">
          <v-icon>mdi-delete-off</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
  </v-badge>
</template>
<script lang="ts">
import Vue from 'vue';
import {NotificationLike} from '@/types/NotificationLike.type';
import moment from 'moment';
import {
  ToDoCompletedNotificationDocument,
} from '@/documentTypes/notifier/discriminators/ToDoCompletedNotification.document';
import OverflowText from '@/components/common/overflow-text.vue';
import ToDoNotificationAcquisitionListsLinkButton
  from "@/components/app/appBar/notifications/appNotifications/components/todoHelpers/toDoNotificationAcquisitionListsLinkButton.vue";
import {ToDoDataTypesEnum} from "@/enums/to-do-manager/ToDoDataTypes.enum";

moment.locale('de');

export default Vue.extend({
  components: {ToDoNotificationAcquisitionListsLinkButton, OverflowText},
  props: {
    notification: {
      type: Object as () => NotificationLike<ToDoCompletedNotificationDocument>,
      required: true,
    },
  },
  computed: {
    moment: () => moment,
    link(): string {
      return this.$router.resolve(`/${this.notification.messagePayload.entityType}/${this.notification.messagePayload.entityId}`).href;
    },
    getDataString(): string | number | undefined {
      const data = this.notification.messagePayload.data as boolean | Date | string | string[] | undefined | number;
      if (typeof data === 'undefined') {
        return undefined;
      }
      if (typeof data === 'boolean') {
        return data ? 'Ja' : 'Nein';
      } else if (this.notification.messagePayload.dataType === ToDoDataTypesEnum.DATE) {
        return moment(data).format('DD.MM.YYYY');
      } else if (Array.isArray(data)) {
        return data.join(', ');
      } else {
        return data as unknown as string | number ;
      }
    },
  },
});
</script>
