import {Serializable} from "@/interfaces/clientOnly/Serializable";

export type UpdateType<T> = T | null | undefined;

export type UpdateObject<T> = Partial<{
    [P in keyof T]: UpdateType<T[P]>;
}>;

function isEmptyOrNullOrUndefined(val: any) {
    return val === '' || val === null || typeof val === 'undefined';
}

export function compareForUpdate<T extends Serializable>(newValue: T | null | undefined, oldValue: T | null | undefined): T | null | undefined {
    if (!isEmptyOrNullOrUndefined(newValue) && isEmptyOrNullOrUndefined(oldValue)) {
        // Return new value if old value was not set
        return newValue;
    }
    if (!isEmptyOrNullOrUndefined(oldValue) && isEmptyOrNullOrUndefined(newValue)) {
        // Return null if oldValue was set and newValue was not -> Delete Prop
        return null;
    }
    if (!isEmptyOrNullOrUndefined(oldValue) && !isEmptyOrNullOrUndefined(newValue) && JSON.stringify(newValue as T) !== JSON.stringify(oldValue as T).toString()) {
        return newValue;
    }
    return undefined;
}

export function compareForUpdateNonNullable<T extends Serializable>(newValue: T | undefined, oldValue: T | null | undefined): T | undefined {
    if (!isEmptyOrNullOrUndefined(newValue) && isEmptyOrNullOrUndefined(oldValue)) {
        // Return new value if old value was not set
        return newValue;
    }
    if (!isEmptyOrNullOrUndefined(oldValue) && isEmptyOrNullOrUndefined(newValue)) {
        // Return null if oldValue was set and newValue was not -> Delete Prop
        return undefined;
    }
    if (!isEmptyOrNullOrUndefined(oldValue) && !isEmptyOrNullOrUndefined(newValue) && JSON.stringify(newValue as T) !== JSON.stringify(oldValue as T)) {
        return newValue;
    }
    return undefined;
}

/**
 * Checks if an object is not empty
 * @param object
 */
export function isNonEmptyDto(object: Record<string, any> | undefined) {
    if (!object) {
        return false;
    }
    const keys = Object.keys(object);
    return keys.some(key => typeof object[key] !== 'undefined');
}
