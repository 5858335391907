import {mapRecordToItems} from '@/helpers/mapRecordToItems.helper';

export enum CustomAccessKeys {
    SEND_MAIL_AS_CUSTOMER = 'sendMailAsCustomer',
    RENDER_MAIL_TEMPLATES = 'renderMailTemplates',
    RENDER_WHATS_APP_MESSAGE_TEMPLATES = 'renderWhatsAppMessageTemplates',
    SHARE_ACCESS = 'shareAccess',
    WRITE_COURSE_LOGS_FOR_OTHERS = 'writeCourseLogsForOthers',
    ALLOCATE_ROLES_TO_USERS = 'allocateRolesToUsers',
    REMOVE_ROLES_FROM_USERS = 'removeRolesFromUsers',
    USE_GMAPS_DISTANCE_MATRIX = 'useGmapsDistanceMatrix',
    START_CRON_JOBS = 'startCronJobs',
    STOP_CRON_JOBS = 'stopCronJobs',
    UPDATE_COURSE_LINKS = 'updateCourseLinks',
    INITIALIZE_PERMISSION_HOOKS = 'initializePermissionHooks',
    USE_SEPA_TOOLS = 'useSepaTools',
    USE_BOOK_INVOICE_GENERATOR = 'useBookInvoiceGenerator',
    LOGIN_AS = 'loginAs',
    CREATE_TEMPORARY_USER = 'createTemporaryUser',
    MANAGE_JOB_AD_PUBLICATIONS = 'manageJobAdPublications',
    READ_STATS = 'readStats',
    CHARGE_PORTOKASSE = 'chargePortokasse',
    SEE_PAYCHECK_OF_OTHERS = 'seePaycheckOfOthers',
    CREATE_PAYCHECK_FOR_OTHERS = 'createPaycheckForOthers',
    AUDIT_PAYCHECKS = 'auditPaychecks',
}

export namespace CustomAccessKeys {
    type Data = {
        text: string;
    }
    export const Record: Record<CustomAccessKeys, Data> = {
        [CustomAccessKeys.SEND_MAIL_AS_CUSTOMER]: {
            text: 'E-Mails über die in den Stammdaten hinterlegte Adresse versenden',
        },
        [CustomAccessKeys.RENDER_MAIL_TEMPLATES]: {
            text: 'E-Mail-Templates rendern',
        },
        [CustomAccessKeys.RENDER_WHATS_APP_MESSAGE_TEMPLATES]: {
            text: 'WhatsApp-Nachrichten-Templates rendern',
        },
        [CustomAccessKeys.SHARE_ACCESS]: {
            text: 'Zugriffsrechte freigegebener Nutzer bearbeiten',
        },
        [CustomAccessKeys.WRITE_COURSE_LOGS_FOR_OTHERS]: {
            text: 'Kurs-Logs für andere schreiben',
        },
        [CustomAccessKeys.ALLOCATE_ROLES_TO_USERS]: {
            text: 'Nutzern Rollen zuweisen',
        },
        [CustomAccessKeys.REMOVE_ROLES_FROM_USERS]: {
            text: 'Rollen von Nutzern entfernen',
        },
        [CustomAccessKeys.USE_GMAPS_DISTANCE_MATRIX]: {
            text: 'Google Maps Distance Matrix nutzen',
        },
        [CustomAccessKeys.START_CRON_JOBS]: {
            text: 'Cron-Jobs starten',
        },
        [CustomAccessKeys.STOP_CRON_JOBS]: {
            text: 'Cron-Jobs stoppen',
        },
        [CustomAccessKeys.UPDATE_COURSE_LINKS]: {
            text: 'Verknüpfungen zwischen Kursen aktualisieren',
        },
        [CustomAccessKeys.INITIALIZE_PERMISSION_HOOKS]: {
            text: 'Berechtigungsroutinen initialisieren',
        },
        [CustomAccessKeys.USE_SEPA_TOOLS]: {
            text: 'SEPA-Tools nutzen',
        },
        [CustomAccessKeys.USE_BOOK_INVOICE_GENERATOR]: {
            text: 'Buchungsrechnungs-Generator nutzen',
        },
        [CustomAccessKeys.LOGIN_AS]: {
            text: 'Als andere Benutzer einloggen',
        },
        [CustomAccessKeys.CREATE_TEMPORARY_USER]: {
            text: 'Temporäre Benutzer erstellen',
        },
        [CustomAccessKeys.MANAGE_JOB_AD_PUBLICATIONS]: {
            text: 'Stellenanzeigen-Veröffentlichungen verwalten',
        },
        [CustomAccessKeys.READ_STATS]: {
            text: 'Statistiken lesen',
        },
        [CustomAccessKeys.CHARGE_PORTOKASSE]: {
            text: 'Portokasse aufladen',
        },
        [CustomAccessKeys.SEE_PAYCHECK_OF_OTHERS]: {
            text: 'Lohnabrechnung anderer einsehen',
        },
        [CustomAccessKeys.CREATE_PAYCHECK_FOR_OTHERS]: {
            text: 'Lohnabrechnung anderer bestätigen',
        },
        [CustomAccessKeys.AUDIT_PAYCHECKS]: {
            text: 'Abrechnungen prüfen und zur Zahlung freigeben',
        },
    };

    export const Items = mapRecordToItems(Record);
}
