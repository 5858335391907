import Axios from 'axios';
import {ExceptionsCollector} from '@/exceptions/ExceptionsCollector';
import {VanillaException} from '@/exceptions/VanillaException';
import {InitializeInstanceRequestDto} from '@/classes/dto/user-login-handler/request/InitializeInstance.request.dto';
import {InitializeInstanceResponseDto} from '@/classes/dto/user-login-handler/response/InitializeInstance.response.dto';

/**
 * API used for initializing the system when blank
 * @class InitializationApi
 * @author mklaczinski
 */
export class InitializationApi {
    private static service = 'user-login-handler';

    /**
     * Returns whether the system can be initialized - if there are no users in the system
     */
    static async canInitialize(): Promise<boolean> {
        try {
            const resp = await Axios.get(`${process.env.VUE_APP_API_PATH}/${this.service}/initialization/can-initialize`);
            return resp.data as boolean;
        } catch (e) {
            throw this.transformException(e);
        }
    }

    /**
     * Initializes the system with the given data. Will attempt to create a new user with admin privileges.
     * Will throw an error if the system is already initialized.
     * @param data - data to initialize the system with
     */
    static async initialize(data: InitializeInstanceRequestDto): Promise<InitializeInstanceResponseDto> {
        try {
            const resp = await Axios.post(`${process.env.VUE_APP_API_PATH}/${this.service}/initialization/initialize`, data);
            return resp.data;
        } catch (e) {
            throw this.transformException(e);
        }
    }

    private static transformException<T>(e: T): VanillaException | T {
        const vanillaException = ExceptionsCollector.tryCast(e);
        if (vanillaException) {
            return vanillaException;
        }
        return e;
    }

}
