<template>
    <v-app>
        <app-bar v-if="$store.state.appReady && (!$route.meta || !$route.meta.hideNavbar)" :showDrawer.sync="showDrawer"/>
        <navigation-drawer v-if="$store.state.axios && $store.state.appReady && (!$route.meta || !$route.meta.hideNavbar)"
                           v-model="showDrawer"/>
        <v-main>
            <router-view :key="$route.fullPath"></router-view>
        </v-main>
        <v-footer v-if="$store.state.loading">
            <v-progress-linear color="info" indeterminate/>
        </v-footer>
        <error-dialog/>
        <unauthorized-dialog/>
        <share-access-dialog v-if="$store.state.axios"/>
        <snackbar-app/>
        <interrupt-snackbar-app/>
        <emergency-logout-dialog/>
        <v-slide-x-reverse-transition>
            <action-button/>
        </v-slide-x-reverse-transition>
        <cookie-banner/>
        <div style="z-index: 999">
          <portal-target name="overlay" multiple/>
        </div>
        <footer>
            <vanilla-footer/>
        </footer>
    </v-app>
</template>

<script lang="ts">
import Vue from 'vue';
import AppBar from '@/components/app/app-bar.app.vue';
import NavigationDrawer from '@/components/app/navigation-drawer.app.vue';
import ErrorDialog from '@/components/app/error-dialog.app.vue';
import UnauthorizedDialog from '@/components/app/unauthorized-dialog.app.vue';
import SnackbarApp from './components/app/snackbar.app.vue';
import ActionButton from '@/components/app/action-button.app.vue';
import ShareAccessDialog from '@/components/app/share-access-dialog.vue';
import EmergencyLogoutDialog from '@/components/app/emergency-logout-dialog.vue';
import InterruptSnackbarApp from '@/components/app/interrupt-snackbar-app.vue';
import CookieBanner from '@/components/app/cookieBanner.app.vue';
import VanillaFooter from '@/components/common/vanillaFooter.vue';

export default Vue.extend({
    name: 'App',
    components: {
        VanillaFooter,
        CookieBanner,
        InterruptSnackbarApp,
        EmergencyLogoutDialog,
        NavigationDrawer,
        AppBar,
        ErrorDialog,
        UnauthorizedDialog,
        SnackbarApp,
        ActionButton,
        ShareAccessDialog,
    },
    data: () => ({
        showDrawer: false,
    }),
    watch: {
        '$vuetify.breakpoint.mdAndUp'(v: boolean) {
            this.$nextTick(() => {
                this.showDrawer = v;
            });
        },
    },
    mounted() {
        if (this.$vuetify.breakpoint.mdAndUp) {
            this.showDrawer = true;
        }
    },
});
</script>

<style src="./global.css"/>
