import {mapRecordToItems} from '@/helpers/mapRecordToItems.helper';

export enum TaxonomyEntryFields {
    TAXONOMY = 'taxonomy',
    NAME = 'name',
    DESCRIPTION = 'description',
    ICON = 'icon',
    COLOR = 'color',
    IS_ARCHIVED = 'isArchived',
    DATA = 'data',
}

export namespace TaxonomyEntryFields {
    type Data = {
        text: string;
        readOnly?: true;
        updateOnly?: true;
    }

    export const Record: Record<TaxonomyEntryFields, Data> = {
        [TaxonomyEntryFields.TAXONOMY]: {
            text: 'Taxonomie',
            readOnly: true,
        },
        [TaxonomyEntryFields.NAME]: {
            text: 'Name',
        },
        [TaxonomyEntryFields.DESCRIPTION]: {
            text: 'Beschreibung',
        },
        [TaxonomyEntryFields.ICON]: {
            text: 'Icon',
        },
        [TaxonomyEntryFields.COLOR]: {
            text: 'Farbe',
        },
        [TaxonomyEntryFields.IS_ARCHIVED]: {
            text: 'Archiviert',
        },
        [TaxonomyEntryFields.DATA]: {
            text: 'Zusätzliche Daten',
        },
    }

    export const Items = mapRecordToItems(Record);
}
