import {mapRecordToItems} from '@/helpers/mapRecordToItems.helper';

export enum ToDoDataTypesEnum {
    NONE = 'none',
    BOOLEAN = 'boolean',
    TEXT = 'text',
    NUMBER = 'number',
    DATE = 'date',
    MULTIPLE_CHOICE = 'multiple-choice',
}

export namespace ToDoDataTypesEnum {
    type Data = {
        text: string;
        icon: string;
    }

    export const Record: Record<ToDoDataTypesEnum, Data> = {
        [ToDoDataTypesEnum.NONE]: {
            text: 'Keine Antwortdaten erforderlich',
            icon: 'mdi-close',
        },
        [ToDoDataTypesEnum.BOOLEAN]: {
            text: 'Ja/Nein',
            icon: 'mdi-check',
        },
        [ToDoDataTypesEnum.TEXT]: {
            text: 'Freitext',
            icon: 'mdi-format-text',
        },
        [ToDoDataTypesEnum.NUMBER]: {
            text: 'Zahl',
            icon: 'mdi-numeric',
        },
        [ToDoDataTypesEnum.DATE]: {
            text: 'Datum',
            icon: 'mdi-calendar',
        },
        [ToDoDataTypesEnum.MULTIPLE_CHOICE]: {
            text: 'Mehrfachauswahl',
            icon: 'mdi-format-list-checks',
        },
    };

    export const Items = mapRecordToItems(Record);
}
